// xxx.js 法语文件
export default {
    app: {
        button:{
            logout: 'se déconnecter',
            submit: 'Soumettre',
            confirm: 'Confirmer',
            cancel: 'Annuler',
            forbidden:'Interdit',
            enable:'Activer',
            dialog_confirm_delete_title:'Confirmer pour supprimer？',
            userpopover_delete_now:'Supprimer immédiatement',
            userpopover_change_password:'Modifier le mot de passe',
            userpopover_delete_user:"Supprimer l'utilisateur",
            userpopover_forbidden:"Désactiver l'utilisateur",
        },
        title: 'Système de gestion ADS',
        home: 'Maison',
        homenotic: "sera la quantité d'appareils expirés：",
        user_manage: 'User',
        mac_manage: 'Appareil',
        ad_manage: 'LES PUBLICITÉS',
        fie_manage: 'Déposer',
        refresh_loosing_text: 'Relâcher pour rafraîchir...',
        refresh_loading_text: 'Uploading...',
        required_username: "Saisir le nom d'utilisateur",
        min_username: "Veuillez saisir un nom d'utilisateur comportant plus de {t} caractères",
        placeholder_username: 'Username',
        add_username: 'Ajouter un utilisateur',
        required_password: 'Veuillez saisir le mot de passe',
        min_password: 'Veuillez saisir un mot de passe de plus de {t} caractères',
        placeholder_password: 'Mot de passe',
        required_confirm_password: 'Veuillez saisir le mot de passe',
        placeholder_confirm_password: 'Confirmer',
        two_password:'Mot de passe différent',
        edit_password:'Modifier le mot de passe',
        required_yzm: 'Veuillez remplir le code de vérification',
        placeholder_yzm: 'Le code de vérification',
        user_count: 'Statistiques des utilisateurs',
        mac_count: "Statistiques de l'appareil",
        ad_count: 'Afficher les statistiques AD',
        resources_count: 'Statistiques des ressources',
        create_time:'Date de création',
        not_more:'No More',
        search:'Veuillez saisir un mot-clé',
        search_user:"Saisir le nom d'utilisateur",
        search_mac:"Veuillez saisir le numéro Mac de l'appareil",
        checkbox_select_max:'Déjà le plus grand',
        userpopover_delete_user_confirm:'Confirmer pour supprimer({u})?',
        userpopover_placeholder_forbidden_note:'Veuillez saisir une remarque',
        user_list_button_dialog_title:'Contrôle des boutons',
        user_list_button_dialog_note:'Commutateur ou non?',
        ad_list_tab_list_total:'Total trouvé {t} annonce',
        ad_list_tab_list_card_title:'Total de {m} vidéo et {i} image',
        ad_list_tab_list_card_type:'Tapez：{t}',
        ad_list_tab_list_card_play_type:'Mode de lecture :{t} {y}',
        ad_list_tab_list_card_mac:'Total {t} appareil',
        ad_list_tab_list_card_day:'Date de sortie：{t}',
        ad_show:'Contenu différent à double écran',
        ad_not_show:'Double écran même contenu',
        ad_host_ping:'Écran 1',
        ad_deputy_ping:'Écran 2',
        ad_complete_ping:'Plein écran',
        ad_two_screens:'Deux écrans partagés',
        ad_three_screen:'Trois écrans partagés',
        ad_divide_ping:'Écran divisé',
        ad_class_ping_vertical:'Écran vertical',
        ad_class_ping_horizontal:'Écran horizontal',
        ad_sort:'Trier la liste de lecture',
        file_del:'Supprimer',
        file_tab_type_1:'Plein écran (image)',
        file_tab_type_2:'Vidéo',
        file_tab_type_3:'Photo',
        file_tab_vertical_type_3:'Trois écrans partagés (image ci-dessus)',
        file_tab_vertical_type_4:'Trois écrans partagés (image ci-dessous)',
        file_tab_vertical_type_5:'Deux écrans partagés (image ci-dessous)',
        file_tab_horizontal_type_3:'Trois écrans partagés (image de gauche)',
        file_tab_horizontal_type_4:'Trois écrans partagés (image de droite)',
        file_upload:'Téléverser un fichier',
        file_upload_user:"Télécharger l'utilisateur",
        file_upload_ing:'Téléchargement...',
        file_upload_success:'Téléchargement réussi',
        file_upload_fail:'Upload Failed',
        file_upload_confirm:'Confirmer le téléchargement',
        file_upload_max_count:'Télécharger Max {t} fichiers à chaque fois',
        file_upload_size:'Chaque fichier Max {t}M',
        file_upload_pixel_size:'Taille recommandée：',
        file_upload_format:'Format de fichier:{t}',
        file_upload_format_error:'Veuillez télécharger les fichiers au format {t}',
        file_upload_filename:'Les noms de fichiers ne peuvent pas avoir de symboles spéciaux！',
        file_release:'Fichier de version',
        file_release_select:'Choisir le fichier',
        file_release_select_num:'Total sélectionné {t}',
        file_release_form_team_id_label:"Type d'écran",
        file_release_form_ping_label:"Sélectionner l'écran",
        file_release_form_palytype_label:'Mode de jeu',
        file_release_form_display_true:'Oui',
        file_release_form_display_false:'Non',
        file_release_form_button_add_video:'Ajouter une vidéo',
        file_release_form_button_add_img:'Ajouter une photo',
        file_release_form_button_add:'Ajouter des fichiers',
        file_release_form_placeholder_video:'Veuillez sélectionner une vidéo',
        file_release_form_placeholder_img:'Veuillez sélectionner une photo',
        file_release_confirm:'Confirmer pour libérer',
        mac_confirm_batch_delete:'Supprimer',
        mac_confirm_batch_replace_user:"Remplacer l'utilisateur",
        mac_confirm_batch_set_up_expire:'Définir la date de validité',
        mac_title_click_select_user:'Sélectionnez un utilisateur',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'En ligne',
        mac_list_mac_status_lx:'Offline',
        mac_list_mac_load_status_done:'Télécharger avec succès',
        mac_list_mac_load_status_waiting:'En attente de téléchargement',
        mac_list_mac_expire:'Expiré',
        mac_list_mac_expire_time:"Date d'expiration",
        mac_list_mac_update_user:'Opérateur de mise à jour',
        mac_list_export_name:"Exportation MAC de l'appareil {t}.xls",
        mac_import_template_name:'Modèle d’importation par lots Devices.xls',
        mac_import_template_button_name:'Ajouter un appareil par lots',
        mac_menu_button_clean:'Nettoyer',
        mac_menu_button_batch_delete:'Supprimer',
        mac_menu_button_batch_replace_user:'Remplacer les utilisateurs',
        mac_menu_button_batch_replace_user_title:"Passer à l'utilisateur",
        mac_menu_button_download_template:'Télécharger le modèle',
        mac_menu_button_set_expire:'Définir la date de validité',
        mac_menu_button_set_expire_select_user:'Sélectionnez un utilisateur',
        mac_menu_button_set_expire_select_device:'Choisir un appareil',
        mac_menu_button_set_expire_select_day:'Sélectionner une date',
    }
}