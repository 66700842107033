<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover">
  <router-view></router-view>
</template>

<script>

import {useRouter} from "vue-router";
import i18n from "@/lang";

export default {
  name: 'App',
  setup(){
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      // 标题 多语言
      document.title = i18n.global.t('app.title')
      next()
    })
  }
}
</script>

<style>
html {
  background-color: #f5f5f5;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  /* 顶部安全区*/
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  /*底部安全区*/
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
