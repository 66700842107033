// xxx.js 土耳其文件
export default {
    app: {
        button:{
            logout: 'Oturumu kapat',
            submit: 'gönder',
            confirm: 'onayla',
            cancel: 'iptal',
            forbidden:'engelli',
            enable:'etkinleştir',
            dialog_confirm_delete_title:'Silme işlemi onaylansın mı?',
            userpopover_delete_now:'Şimdi sil',
            userpopover_change_password:'Şifreyi değiştir',
            userpopover_delete_user:'Kullanıcıyı sil',
            userpopover_forbidden:'devre dışı',
        },
        title: 'Reklam Yönetim Sistemi',
        home: 'ön Sayfa',
        homenotic: 'Son kullanma tarihi dolmak üzere olan bir ekipman grubunuz var. Kullanmaya devam etmek istiyorsanız lütfen satıcıyla iletişime geçin! Süresi dolmuş ekipman şunları içerir:',
        user_manage: 'Kullanıcı yönetimi',
        mac_manage: 'Cihaz Yönetimi',
        ad_manage: 'Reklam Yönetimi',
        fie_manage: 'Dosya yönetimi',
        refresh_loosing_text: 'Yenilemek için bırakın...',
        yenileme_loading_text: 'Yükleniyor...',
        required_username: 'Lütfen kullanıcı adını girin',
        min_username: 'Lütfen {t} karakterden daha uzun bir kullanıcı adı girin',
        placeholder_username: 'kullanıcı adı',
        add_username: 'Kullanıcı ekle',
        required_password: 'Lütfen şifreyi giriniz',
        min_password: 'Lütfen {t} karakterden uzun bir şifre girin',
        placeholder_password: 'şifre',
        required_confirm_password: 'Lütfen onay şifresini girin',
        placeholder_confirm_password: 'Şifreyi onayla',
        two_password:'İki şifre tutarsız',
        edit_password:'Şifreyi düzenle',
        required_yzm: 'Lütfen doğrulama kodunu giriniz',
        placeholder_yzm: 'Doğrulama kodu',
        user_count: 'Kullanıcı istatistikleri',
        mac_count: 'Cihaz istatistikleri',
        ad_count: 'Reklam istatistikleri',
        resources_count: 'kaynak istatistikleri',
        create_time:'Oluşturma zamanı',
        not_more:'Artık yok',
        search:'Lütfen arama anahtar kelimesini girin',
        search_user:'Lütfen arama hesabını girin',
        search_mac:'Lütfen arama MAC adresini girin',
        checkbox_select_max:'Maksimum sayıya ulaşıldı',
        userpopover_delete_user_confirm:'Kullanıcının ({u}) silinmesini onaylıyor musunuz?',
        userpopover_placeholder_forbidden_note:'Lütfen yasak notu girin',
        user_list_button_dialog_title:'Düğme kontrolleri',
        user_list_button_dialog_note:'Do you want to switch the switch?',
        ad_list_tab_list_total:'Toplam {t} reklam bulundu',
        ad_list_tab_list_card_title:'Toplam {m} video, {i} resim',
        ad_list_tab_list_card_type:'Tür: {t}',
        ad_list_tab_list_card_play_type:'Oynatma modu: {t} {y}',
        ad_list_tab_list_card_mac:'Reklam makinesi: Toplamda {t} birim yerleştirildi',
        ad_list_tab_list_card_day:'Yayın tarihi: {t}',
        ad_show:'Farklı ekranı destekle',
        ad_not_show:'Farklı görüntülemeyi desteklemiyor',
        ad_host_ping:'Ana ekran',
        ad_deputy_ping:'ikincil ekran',
        ad_complete_ping:'tam ekran',
        ad_two_screens:'iki ekran',
        ad_third_screen:'üç ekran',
        ad_divide_ping:'bölünmüş ekran',
        ad_class_ping_vertical:'Dikey ekran',
        ad_class_ping_horizontal:'Yatay ekran',
        ad_sort:'sırala',
        file_del:'Toplu sil',
        file_tab_type_1:'Tam ekran resmi',
        file_tab_type_2:'Video',
        file_tab_type_3:'resim',
        file_tab_vertical_type_3:'Üç ekran resmi (üstte)',
        file_tab_vertical_type_4:'Üç ekran resmi (aşağıda)',
        file_tab_vertical_type_5:'İki ekran resmi (altta)',
        file_tab_horizontal_type_3:'Üç ekran resmi (sol)',
        file_tab_horizontal_type_4:'Üç ekran resmi (sağda)',
        file_upload:'Dosya yükle',
        file_upload_user:'kullanıcı yükle',
        file_upload_ing:'Yükleniyor...',
        file_upload_success:'Yükleme başarılı',
        file_upload_fail:'Yükleme başarısız oldu',
        file_upload_confirm:'Yüklemeyi onayla',
        file_upload_max_count:'Her seferinde {t} dosyadan fazlası yüklenemez',
        file_upload_size:'Dosya boyutu gereksinimi: maksimum {t}M den fazla değil',
        file_upload_pixel_size:'Önerilen boyut:',
        file_upload_format:'Dosya formatı: {t}',
        file_upload_format_error:'Lütfen {t} formatındaki dosyayı yükleyin',
        file_upload_filename:'Not: Dosya adında özel semboller bulunamaz! ',
        file_release:'dosyayı yayınla',
        file_release_select:'Dosya seç',
        file_release_select_num:'Toplam seçim {t}',
        file_release_form_team_id_label:'ekran türü',
        file_release_form_ping_label:'Seçim ekranı',
        file_release_form_palytype_label:'Oynatma yöntemi',
        file_release_form_display_true:'evet',
        file_release_form_display_false:'Hayır',
        file_release_form_button_add_video:'Video ekle',
        file_release_form_button_add_img:'Resim ekle',
        file_release_form_button_add:'Dosya ekle',
        file_release_form_placeholder_video:'Lütfen bir video seçin',
        file_release_form_placeholder_img:'Lütfen bir resim seçin',
        file_release_confirm:'Yayınlamayı onayla',
        mac_confirm_batch_delete:'Toplu silme',
        mac_confirm_batch_replace_user:'Kullanıcıları toplu değiştir',
        mac_confirm_batch_set_up_expire:'Geçerlilik süresini ayarla',
        mac_title_click_select_user:'Kullanıcıyı seçmek için tıklayın',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'çevrimiçi',
        mac_list_mac_status_lx:'çevrimdışı',
        mac_list_mac_load_status_done:'İndirme tamamlandı',
        mac_list_mac_load_status_waiting:'İndirme bekleniyor',
        mac_list_mac_expire:'Süresi doldu',
        mac_list_mac_expire_time:'Son kullanma süresi',
        mac_list_mac_update_user:'Güncelleme operatörü',
        mac_list_export_name:'Cihazın MAC dışa aktarımı {t}.xls',
        mac_import_template_name:'Cihaz toplu içe aktarma şablonu.xls',
        mac_import_template_button_name:'Cihazları toplu içe aktarma',
        mac_menu_button_clean:'Temizle',
        mac_menu_button_batch_delete:'Toplu silme',
        mac_menu_button_batch_replace_user:'Kullanıcıları toplu değiştir',
        mac_menu_button_batch_replace_user_title:'Lütfen kullanıcıya geçmeyi seçin',
        mac_menu_button_download_template:'Şablonu indir',
        mac_menu_button_set_expire:'Son kullanma tarihini ayarla',
        mac_menu_button_set_expire_select_user:'Kullanıcı seç',
        mac_menu_button_set_expire_select_device:'Cihaz seç',
        mac_menu_button_set_expire_select_day:'Tarih seç',
    }
}