// en_us.js 英文文件
export default {
    app: {
        button:{
            logout: 'Sign out',
            submit: 'Submit',
            confirm: 'Confirm',
            cancel: 'Cancel',
            forbidden:'Forbidden',
            enable:'Enable',
            dialog_confirm_delete_title:'Confirm to Delete？',
            userpopover_delete_now:'Delete Immediately',
            userpopover_change_password:'Modify Password',
            userpopover_delete_user:'Delete User',
            userpopover_forbidden:'Dsiable User',
        },
        title: 'ADS Management System',
        home: 'Home',
        homenotic: 'will be expired devices qty.:',
        user_manage: 'User',
        mac_manage: 'Device',
        ad_manage: 'ADS',
        fie_manage: 'File',
        refresh_loosing_text: 'Release to refresh',
        refresh_loading_text: 'Uploading',
        required_username: 'Input Username',
        min_username: 'Please enter a username with more than {t} characters',
        placeholder_username: 'Username',
        add_username: 'Add User',
        required_password: 'Please Input Password',
        min_password: 'Please enter a password of more than {t} characters',
        placeholder_password: 'Password',
        required_confirm_password: 'Please Input Password',
        placeholder_confirm_password: 'Confirm',
        two_password:'Password Different.',
        edit_password:'Edit Password',
        required_yzm: 'Please fill in the verification code',
        placeholder_yzm: 'Verification code',
        user_count: 'User Statistics',
        mac_count: 'Device Statistics',
        ad_count: 'Display AD Statistics',
        resources_count: 'Resource statistics',
        create_time:'Creation Date',
        not_more:'No More',
        search:'Please enter a keyword',
        search_user:'Input Username',
        search_mac:'Please Input Device Mac Number.',
        checkbox_select_max:'Already Largest',
        userpopover_delete_user_confirm:'Confirm to Delete({u})?',
        userpopover_placeholder_forbidden_note:'Please enter a remark',
        user_list_button_dialog_title:'Button controls',
        user_list_button_dialog_note:'Do you want to switch the switch?',
        ad_list_tab_list_total:'Total Found {t} AD',
        ad_list_tab_list_card_title:'Total {m} Video and {i} Picture',
        ad_list_tab_list_card_type:'Type：{t}',
        ad_list_tab_list_card_play_type:'Play Mode：{t} {y}',
        ad_list_tab_list_card_mac:'Total {t} Device',
        ad_list_tab_list_card_day:'Release Date：{t}',
        ad_show:'Dual Screen Different Contents',
        ad_not_show:'Dual Screen Same Contents',
        ad_host_ping:'Screen 1',
        ad_deputy_ping:'Screen 2',
        ad_complete_ping:'Full Screen',
        ad_two_screens:'Two Split-Screen',
        ad_three_screen:'Three Split-Screen',
        ad_divide_ping:'Split Screen',
        ad_class_ping_vertical:'Vertical Screen',
        ad_class_ping_horizontal:'Horizontal Screen',
        ad_sort:'Sort Playlist',
        file_del:'Delete',
        file_tab_type_1:'Full Screen (Picture)',
        file_tab_type_2:'Video',
        file_tab_type_3:'Photo',
        file_tab_vertical_type_3:'Three Split-Screen (Picture Above)',
        file_tab_vertical_type_4:'Three Split-Screen (Picture Below)',
        file_tab_vertical_type_5:'Two Split-Screen (Picture Below)',
        file_tab_horizontal_type_3:'Three Split-Screen (Left Picture)',
        file_tab_horizontal_type_4:'Three Split-Screen (Right Picture)',
        file_upload:'Upload File',
        file_upload_user:'Upload User',
        file_upload_ing:'Uploading...',
        file_upload_success:'Upload Successfully',
        file_upload_fail:'Upload Failed',
        file_upload_confirm:'Confirm Upload',
        file_upload_max_count:'Upload Max {t} File Each Time',
        file_upload_size:'Each File Max {t}M',
        file_upload_pixel_size:'Recommended Size:',
        file_upload_format:'File Format:{t}',
        file_upload_format_error:'Please upload files in {t} format',
        file_upload_filename:'File names cannot have special symbols',
        file_release:'Release File',
        file_release_select:'Choose File',
        file_release_select_num:'Total Selected {t}',
        file_release_form_team_id_label:'Screen Type',
        file_release_form_ping_label:'Select Screen',
        file_release_form_palytype_label:'Play Mode',
        file_release_form_display_true:'Yes',
        file_release_form_display_false:'No',
        file_release_form_button_add_video:'Add Video',
        file_release_form_button_add_img:'Add Photo',
        file_release_form_button_add:'Add Files',
        file_release_form_placeholder_video:'Please select a video',
        file_release_form_placeholder_img:'Please select a photo',
        file_release_confirm:'Confirm to Release',
        mac_confirm_batch_delete:'Delete',
        mac_confirm_batch_replace_user:'Replace User',
        mac_confirm_batch_set_up_expire:'Set Valid Date',
        mac_title_click_select_user:'Select User',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'Online',
        mac_list_mac_status_lx:'Offline',
        mac_list_mac_load_status_done:'Download Successfully',
        mac_list_mac_load_status_waiting:'Waiting for download',
        mac_list_mac_expire:'Expired',
        mac_list_mac_expire_time:'Expiration Date',
        mac_list_mac_update_user:'Update Operator',
        mac_list_export_name:'Device MAC export {t}.xls',
        mac_import_template_name:'Template Of Batch Import Devices.xls',
        mac_import_template_button_name:'Batch Add Device',
        mac_menu_button_clean:'Clear Up',
        mac_menu_button_batch_delete:'Delete',
        mac_menu_button_batch_replace_user:'Replace users',
        mac_menu_button_batch_replace_user_title:'Move to The User',
        mac_menu_button_download_template:'Download Template',
        mac_menu_button_set_expire:'Set Valid Date',
        mac_menu_button_set_expire_select_user:'Select User',
        mac_menu_button_set_expire_select_device:'Select Device',
        mac_menu_button_set_expire_select_day:'Select Date',
    }
}