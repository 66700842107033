// xxx.js 西班牙文件
export default {
    app: {
        button:{
            logout: 'Cancelar cuenta',
            submit: 'Entregar',
            confirm: 'Confirmar',
            cancel: 'Cancelar',
            forbidden:'Prohibido',
            enable:'Permitir',
            dialog_confirm_delete_title:'Confirmar para eliminar？',
            userpopover_delete_now:'Eliminar inmediatamente',
            userpopover_change_password:'Modificar la contraseña',
            userpopover_delete_user:'Borrar usuario',
            userpopover_forbidden:'Deshabilitar usuario',
        },
        title: 'Sistema de gestión de anuncios',
        home: 'Hogar',
        homenotic: 'La cantidad de dispositivos caducados será：',
        user_manage: 'Usuario',
        mac_manage: 'Dispositivo',
        ad_manage: 'ANUNCIOS',
        fie_manage: 'Archivo',
        refresh_loosing_text: 'Suéltelo para actualizar',
        refresh_loading_text: 'Subiendo...',
        required_username: 'Introduzca el nombre de usuario',
        min_username: 'Por favor ingresa un nombre de usuario con más de{t}caracteres',
        placeholder_username: 'Nombre de usuario',
        add_username: 'Agregar usuario',
        required_password: 'Por favor ingrese la contraseña',
        min_password: 'Por favor ingresa una contraseña de más de{t}caracteres',
        placeholder_password: 'Contraseña',
        required_confirm_password: 'Por favor ingrese la contraseña',
        placeholder_confirm_password: 'Confirmar',
        two_password:'Contraseña diferente',
        edit_password:'Editar contraseña',
        required_yzm: 'Por favor complete el código de verificación',
        placeholder_yzm: 'Código de verificación',
        user_count: 'Estadísticas de usuario',
        mac_count: 'Estadísticas del dispositivo',
        ad_count: 'Mostrar estadísticas de AD',
        resources_count: 'Estadísticas de recursos',
        create_time:'Fecha de creación',
        not_more:'No más',
        search:'Por favor ingrese una palabra clave',
        search_user:'Introduzca el nombre de usuario',
        search_mac:'Ingrese el número de Mac del dispositivo',
        checkbox_select_max:'Ya más grande',
        userpopover_delete_user_confirm:'Confirmar para eliminar({u})?',
        userpopover_placeholder_forbidden_note:'Por favor introduce un comentario',
        user_list_button_dialog_title:'Control de botones',
        user_list_button_dialog_note:'Si cambiar el interruptor?',
        ad_list_tab_list_total:'Total encontrado {t} AD',
        ad_list_tab_list_card_title:'Total de {m} vídeo y {i} imagen',
        ad_list_tab_list_card_type:'Tipo：{t}',
        ad_list_tab_list_card_play_type:'Modo de juego：{t} {y}',
        ad_list_tab_list_card_mac:'Total de {t} dispositivos',
        ad_list_tab_list_card_day:'Fecha de lanzamiento：{t}',
        ad_show:'Pantalla dual diferentes contenidos',
        ad_not_show:'Pantalla dual Mismo contenido',
        ad_host_ping:'Pantalla 1',
        ad_deputy_ping:'Pantalla 2',
        ad_complete_ping:'Pantalla completa',
        ad_two_screens:'Dos pantallas divididas',
        ad_three_screen:'Tres pantallas divididas',
        ad_divide_ping:'Pantalla dividida',
        ad_class_ping_vertical:'Pantalla vertical',
        ad_class_ping_horizontal:'Pantalla horizontal',
        ad_sort:'Ordenar lista de reproducción',
        file_del:'Borrar',
        file_tab_type_1:'Pantalla completa (imagen)',
        file_tab_type_2:'Video',
        file_tab_type_3:'Foto',
        file_tab_vertical_type_3:'Tres pantallas divididas (imagen de arriba)',
        file_tab_vertical_type_4:'Tres pantallas divididas (imagen de abajo)',
        file_tab_vertical_type_5:'Dos pantallas divididas (imagen de abajo)',
        file_tab_horizontal_type_3:'Tres pantallas divididas (imagen izquierda)',
        file_tab_horizontal_type_4:'Tres pantallas divididas (imagen derecha)',
        file_upload:'Subir archivo',
        file_upload_user:'Subir usuario',
        file_upload_ing:'Subiendo...',
        file_upload_success:'Subir con éxito',
        file_upload_fail:'Subida fallida',
        file_upload_confirm:'Confirmar carga',
        file_upload_max_count:'Cargue un máximo de {t} archivo cada vez',
        file_upload_size:'Cada archivo máx. {t}M',
        file_upload_pixel_size:'Tamaño recomendado：',
        file_upload_format:'Formato de archivo:{t}',
        file_upload_format_error:'Sube archivos en formato {t}',
        file_upload_filename:'Los nombres de archivos no pueden tener símbolos especiales.',
        file_release:'Liberar archivo',
        file_release_select:'Elija el archivo',
        file_release_select_num:'Total seleccionado {t}',
        file_release_form_team_id_label:'Tipo de pantalla',
        file_release_form_ping_label:'Seleccionar pantalla',
        file_release_form_palytype_label:'Modo de juego',
        file_release_form_display_true:'Sí',
        file_release_form_display_false:'No',
        file_release_form_button_add_video:'Añadir video',
        file_release_form_button_add_img:'Añadir foto',
        file_release_form_button_add:'Agregar archivos',
        file_release_form_placeholder_video:'Por favor seleccione un vídeo',
        file_release_form_placeholder_img:'Por favor seleccione una foto',
        file_release_confirm:'Confirmar para liberar',
        mac_confirm_batch_delete:'Borrar',
        mac_confirm_batch_replace_user:'Reemplazar usuario',
        mac_confirm_batch_set_up_expire:'Establecer fecha válida',
        mac_title_click_select_user:'Seleccionar usuario',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'En línea',
        mac_list_mac_status_lx:'Desconectado',
        mac_list_mac_load_status_done:'Descargar con éxito',
        mac_list_mac_load_status_waiting:'Esperando descarga',
        mac_list_mac_expire:'Caducado',
        mac_list_mac_expire_time:'Fecha de caducidad',
        mac_list_mac_update_user:'Actualizar operador',
        mac_list_export_name:'Exportación MAC del dispositivo {t}.xls',
        mac_import_template_name:'Plantilla de dispositivos de importación por lotes.xls',
        mac_import_template_button_name:'Agregar dispositivo por lotes',
        mac_menu_button_clean:'Aclarar',
        mac_menu_button_batch_delete:'Borrar',
        mac_menu_button_batch_replace_user:'Reemplazar usuarios',
        mac_menu_button_batch_replace_user_title:'Mover al usuario',
        mac_menu_button_download_template:'Descargar plantilla',
        mac_menu_button_set_expire:'Establecer fecha válida',
        mac_menu_button_set_expire_select_user:'Seleccionar usuario',
        mac_menu_button_set_expire_select_device:'Seleccione el dispositivo',
        mac_menu_button_set_expire_select_day:'Seleccione fecha',
    }
}