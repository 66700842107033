"use strict";
import { createRouter,createWebHashHistory } from 'vue-router'
import {getToken,removeToken} from '@/utils/token'
import {logout} from "@/api/api";
import {showSuccessToast} from "vant";

// 1. 定义路由组件：这里直接用的对象数据，也可以导入其他组件。
const action_login = '/login'
const Logout = { render(){
        logout()
        showSuccessToast("ok");
        setTimeout(() => {
            //需要定时执行的代码
            removeToken()
            router.push(action_login)
        }, 2000)
    }}
// 2. 定义一些路由：每个路由都需要映射到一个组件。
const routes = [
    { path: '/', component : () => import("@/views/site/index") },
    { path: '/applist', component : () => import("@/views/app/applist") },
    { path: '/appdetails/:id/:name/:content/:url/:ctime/:forced/:code/:istype',name:'appdetails', component : () => import("@/views/app/appdetails") },
    { path: '/appupload', component : () => import("@/views/app/upload") },
    { path: '/user', component : () => import("@/views/user/user") },
    { path: '/mac', component : () => import("@/views/mac/mac") },
    { path: '/ad', component : () => import("@/views/mat/ad") },
    { path: '/adlist/:id/:username/:mp4/:img/:ad_num',name:'adlist', component : () => import("@/views/mat/adlist") },
    { path: '/file', component : () => import("@/views/mat/file") },
    { path: '/matlist/:id/:username/:mp4/:img/:ad_num',name:'matlist', component : () => import("@/views/mat/matlist") },
    { path: '/upload/:id/:teamid/:type',name:'upload', component : () => import("@/views/mat/upload") },
    { path: '/release/:id/:teamid/:type',name:'release', component : () => import("@/views/mat/release") },
    { path: action_login, component : () => import("@/views/site/login") },
    { path: '/logout', component: Logout },
]

// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({
    // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

// 4. 防止用户跳过登录页面
router.beforeEach((to, from, next) => {
    if (to.path === action_login) {
        next()
    } else {
        const token = getToken()
        //判断token是否存在，如果不存在，跳转到登录页面，如果存在，则放行
        if (!token) {
            next(action_login)
        } else {
            next()
        }
    }
})

export default router